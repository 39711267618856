import { BehaviorSubject, Observable, combineLatest, of } from 'rxjs';
import { FootnoteService, ProximalService } from '@frk/eds-components';
import { debounceTime, delay, map, shareReplay, tap } from 'rxjs/operators';
import {
  Alert,
  Alerts,
  CaveatCollection,
  FundId,
  ShareClassCode,
} from '@types';
import { CaveatDataService } from '@products/caveats/services/caveat-data.service';
import { GraphQLFundDataService } from '@products/services/graphql-fund-data.service';
import { Injectable } from '@angular/core';
import { Logger } from '@utils/logger';
import { SegmentService } from '@services/segment.service';
import { DebugService } from '@services/debug.service';
import { Component, Page } from '@bloomreach/spa-sdk';
import query from '@graphql/caveats/caveats.graphql';
import { Router } from '@angular/router';
import { SiteConfigService } from '@services/site-config.service';

const logger = Logger.getLogger('CaveatService');

/**
 * Caveats Service to provide proximal, footnote and legal caveats to all pages.
 *
 * Relies on 3 sources of data:
 *  1. caveat feed for the taxonomy country/language.
 *  2. fund data (if provided) to filter on specific fund details.
 *  3. the current segment.
 *
 * The caveat feed loads ALL the caveats for the site.
 * This is processed to make it easier to filter the caveats for a specific page by type.
 * Individual caveats then subscribe and receive the data they need based on placement parameter.
 *
 * Footnote Order
 * ==============
 * Cant work out the footnote order from order of registration as components load asynchronously.
 * Cant work out the footnote order from a query selector as components in unfocussed tabs will not be rendered.
 * So we assume registration order within a component is in page order.
 * And we encode the Component name within the placement.
 * And we sort based on the component order from the resourceapi response (which works as long as the page layouts maintain that order).
 */
@Injectable({
  providedIn: 'root',
})
export class CaveatService implements FootnoteService, ProximalService {
  filteredCaveats$: Observable<CaveatCollection>;
  filteredFootnotes$: Observable<any>;
  fundData: any = {};
  fundData$ = new BehaviorSubject(this.fundData);
  registeredFootnotesPlacements: string[] = [];
  registeredFootnotesPlacements$: BehaviorSubject<
    string[]
  > = new BehaviorSubject(this.registeredFootnotesPlacements);
  currentFundId: string;
  currentShareClassCode: string;
  showDummyCaveats = true;
  footnotesByComponent: { [comp: string]: string[] } = {};
  componentOrder: string[] = [];
  footnoteOrder: string[] = [];
  isSiteIntl = false;

  constructor(
    caveatDataService: CaveatDataService,
    private fundDataService: GraphQLFundDataService,
    segmentService: SegmentService,
    private debugService: DebugService,
    private router: Router,
    private siteConfig: SiteConfigService
  ) {
    this.debugService
      .isShowDummyCaveats$()
      .subscribe((flag) => (this.showDummyCaveats = flag));

    this.filteredCaveats$ = combineLatest([
      caveatDataService.getData$(),
      segmentService.getCurrentSegmentId$(),
      this.fundData$,
    ]).pipe(
      tap((vals) => logger.debug('sources loaded', vals)),
      map((vals) => {
        this.isSiteIntl = this.siteConfig.isSiteInternational();
        return this.filterCaveats(vals);
      }),
      tap((caveats) => logger.debug('caveats filtered', caveats)),
      shareReplay(1)
    );

    this.filteredFootnotes$ = combineLatest([
      this.registeredFootnotesPlacements$.pipe(debounceTime(200)),
      this.filteredCaveats$.pipe(
        map((caveats) => {
          return caveats.footnotes.reduce((acc, footnote) => {
            // aggregate the footnotes by placement.
            footnote.placements?.forEach((placement) => {
              if (acc[placement] === undefined) {
                acc[placement] = [footnote];
              } else {
                acc[placement].push(footnote);
              }
            });
            return acc;
          }, {});
        })
      ),
    ]).pipe(
      map(([registeredPlacements, footnotesByPlacement]) => {
        const populatedPlacements = registeredPlacements.filter(
          (registeredPlacement) =>
            footnotesByPlacement[registeredPlacement] !== undefined
        );

        if (populatedPlacements) {
          // need to reindex
          populatedPlacements.forEach((registeredPlacement) =>
            footnotesByPlacement[registeredPlacement].forEach(
              (footnote) => delete footnote.index
            )
          );
          const orderedPopulatedPlacements = this.sortPlacementsByPageOrder(
            populatedPlacements,
            this.footnoteOrder
          );
          let i = 1;
          // work out indexes based on order of placements and number of footnotes for each placement
          const ordered = orderedPopulatedPlacements.map(
            (registeredPlacement) => ({
              placement: registeredPlacement,
              content: footnotesByPlacement[registeredPlacement].filter(
                (footnote) => footnote.index === undefined
              ), // only want content for new footnotes
              indexes: footnotesByPlacement[registeredPlacement]
                .map((footnote) => {
                  // if the footnote already has an index (because it is in another placement) then use that.
                  if (footnote.index === undefined) {
                    footnote.index = String(i++);
                  }
                  return footnote.index;
                })
                .sort((a, b) => {
                  return +a - +b;
                }),
            })
          );
          const mapped = ordered.reduce((acc, placement) => {
            // convert to object with placement as key
            acc[placement.placement] = placement;
            return acc;
          }, {});
          return {
            ordered,
            mapped,
          };
        }
        return {
          ordered: [],
          mapped: {},
        };
      }),
      tap((footnotes) => logger.debug('filteredFootnotes', footnotes)),
      shareReplay(1)
    );
  }

  private sortPlacementsByPageOrder(
    populatedPlacements: string[],
    placementsOrder: string[]
  ): string[] {
    return populatedPlacements.sort((a, b) => {
      let ai = placementsOrder.indexOf(a);
      let bi = placementsOrder.indexOf(b);
      if (ai === -1) {
        ai = 99999;
      }
      if (bi === -1) {
        bi = 99999;
      }
      if (ai < bi) {
        return -1;
      }
      if (ai > bi) {
        return 1;
      }
      return 0;
    });
  }

  /**
   * Called by the legal caveats component
   * @param page - the current page
   * @param fundId - set by the route or component
   * @param shareClassCode - set by the route or component
   */
  setCaveatFilter(fundId?: string, shareClassCode?: string): void {
    if (!fundId) {
      logger.debug('not a fund specific page');
      // not a fund specific page
      this.fundData = {};
      this.fundData$.next(this.fundData); // should we remove caveats with a fund configured?
      /**
       * WDE-5613 Resolves refresh issue for ETF link which is not displayed in fund page.
       * It was working on very first instance, when moving to PPSS page and coming back to same fund page the fund data is getting erased.
       * On first occurrence the 2nd If condition takes the fund ID and ShareClassCode. Since it is undefined.
       *  We are making use of below code to make it null while emptying the fund data.
       */
      this.currentFundId = null;
      this.currentShareClassCode = null;
      return;
    }
    if (
      fundId !== this.currentFundId ||
      shareClassCode !== this.currentShareClassCode
    ) {
      logger.debug('fund specific page', fundId, shareClassCode);
      this.currentFundId = fundId;
      this.currentShareClassCode = shareClassCode;
      this.fundDataService
        .register(query, {
          fundId,
          shareClassCode,
          countryCode: this.fundDataService.getCountry(),
          languageCode: this.fundDataService.getLanguage(),
        })
        .subscribe((data) => {
          const benchmark =
            data.data.Overview.shareclass?.[0]?.bmassoc?.map(
              (bm) => bm?.bmacctnum
            ) ?? [];
          this.fundData = {
            fundId,
            fundShareClassCode: `${fundId}-${shareClassCode}`,
            investmentManager: data.data.Overview.invmangr,
            assetClass: data.data.Overview.assetclassstd,
            taxonomy: data.data.Overview.webprdcttaxonomy,
            sfdrcategory: data.data.Overview.sfdrcategory,
            benchmark,
          };

          this.fundData$.next(this.fundData);
        });
    }
  }

  /**
   * filter the set of all caveats so only those applicable to this page are available
   */
  private filterCaveats(vals: any[]): CaveatCollection {
    const [caveats, segment, fundData] = vals;
    if (fundData.fundId === undefined) {
      return {
        legals: caveats.legals.filter((legal) =>
          this.isCaveatApplicable(legal, segment)
        ),
        proximals: caveats.proximals.filter((proximal) =>
          this.isCaveatApplicable(proximal, segment)
        ),
        footnotes: caveats.footnotes.filter((footnote) =>
          this.isCaveatApplicable(footnote, segment)
        ),
        alerts: caveats.alerts,
      };
    }
    return {
      legals: caveats.legals?.filter((legal) =>
        this.isCaveatApplicable(legal, segment, fundData)
      ),
      proximals: caveats.proximals?.filter((proximal) =>
        this.isCaveatApplicable(proximal, segment, fundData)
      ),
      footnotes: caveats.footnotes?.filter((footnote) =>
        this.isCaveatApplicable(footnote, segment, fundData)
      ),
      alerts: caveats.alerts,
    };
  }

  /**
   * a caveat is applicable if segments and fund tagging match the current context
   */
  private isCaveatApplicable(caveat, segment: string, fundData?: any): boolean {
    if (fundData === undefined) {
      return (
        this.emptyOrIncludes(caveat.segments, segment) &&
        this.empty(caveat.fl) &&
        this.empty(caveat.fscl) &&
        this.empty(caveat.acl) &&
        this.empty(caveat.tl) &&
        this.empty(caveat.iml) &&
        this.empty(caveat.sfdr) &&
        this.empty(caveat.bma)
      );
    }
    return (
      this.emptyOrIncludes(caveat.segments, segment) &&
      this.emptyOrIncludes(caveat.fl, fundData.fundId) &&
      this.emptyOrIncludes(caveat.fscl, fundData.fundShareClassCode) &&
      this.emptyOrIncludes(caveat.acl, fundData.assetClass) &&
      this.emptyOrIncludes(caveat.tl, fundData.taxonomy) &&
      this.emptyOrIncludes(caveat.iml, fundData.investmentManager) &&
      this.emptyOrIncludes(caveat.sfdr, fundData.sfdrcategory) &&
      this.emptyOrHaveCommonItems(caveat.bma, fundData.benchmark)
    );
  }

  private emptyOrIncludes(arrayField: string[], matcher: string): boolean {
    return (
      arrayField === undefined ||
      arrayField.length === 0 ||
      arrayField.includes(matcher)
    );
  }

  private emptyOrHaveCommonItems(
    arrayField: string[],
    matcherField: string[]
  ): boolean {
    if (arrayField === undefined || arrayField.length === 0) {
      return true; // Return true for undefined or empty arrayField
    }

    const commonItems = arrayField.some((item) => matcherField.includes(item));
    return commonItems;
  }

  private empty(arrayField: string[]): boolean {
    return arrayField === undefined || arrayField.length === 0;
  }

  private parseComponentPlacement(componentPlacement: string): string[] {
    if (componentPlacement.indexOf(':') === -1) {
      logger.warn('unidentified component for placement', componentPlacement);
      return ['unidentified', componentPlacement];
    } else {
      return componentPlacement.split(':');
    }
  }
  /**
   * Get the footnotes refs for a particular placement. This also registers the footnote placement.
   */
  getRefs$(componentPlacement: string): Observable<string[]> {
    const [component, placement] = this.parseComponentPlacement(
      componentPlacement
    );
    this.updateFootnoteOrder(component, placement);

    logger.debug('footnote registered', placement);
    if (this.showDummyCaveats) {
      if (!this.registeredFootnotesPlacements.includes(placement)) {
        this.registeredFootnotesPlacements.push(placement);
      }
      return of([
        String(this.registeredFootnotesPlacements.indexOf(placement) + 1),
      ]);
    }
    // wrap filteredFootnotes$ to ensure we remove from registeredFootnotePlacements on unsubscribe
    return new Observable((subscriber) => {
      const sub = this.filteredFootnotes$
        .pipe(map((footnotes) => footnotes.mapped[placement]?.indexes || []))
        .subscribe(subscriber);
      if (!this.registeredFootnotesPlacements.includes(placement)) {
        this.registeredFootnotesPlacements.push(placement);
        this.registeredFootnotesPlacements$.next(
          this.registeredFootnotesPlacements.slice(0)
        );
      }
      return () => {
        sub.unsubscribe();
        // remove registration for this placement
        const index = this.registeredFootnotesPlacements.indexOf(placement);
        if (index > -1) {
          this.registeredFootnotesPlacements.splice(index, 1);
          this.registeredFootnotesPlacements$.next(
            this.registeredFootnotesPlacements
          );
        }
      };
    });
  }

  /**
   * get a list of footnote paragraphs in correct order to show at bottom of page.
   */
  getFootnoteContents$(): Observable<any> {
    if (this.showDummyCaveats) {
      return of(this.registeredFootnotesPlacements).pipe(delay(3000));
    }
    return this.filteredFootnotes$.pipe(
      tap((footnotes) => logger.debug('footnote content', footnotes)),
      map((footnotes) =>
        footnotes.ordered.reduce((combined: any[], placement: any) => {
          return combined.concat(
            placement.content.reduce(
              (contents: string[], footnote) =>
                contents.concat(footnote.content),
              []
            )
          );
        }, [])
      )
    );
  }

  /**
   * get the proximal caveats for a particular placement
   */
  getProximals$(placement: string): Observable<string[]> {
    if (this.showDummyCaveats) {
      return of([`Dummy proximal content for placement: ${placement}`]);
    }
    return this.filteredCaveats$.pipe(
      map((caveats) => {
        return caveats.proximals
          ?.filter((proximal) => proximal.placements.includes(placement))
          .sort((a, b) => this.legalProximalCaveatSort(a.priority, b.priority))
          .map((proximal) => {
            return !this.isSiteIntl
              ? `<div class="small">${this.fixLocalLinks(
                  proximal.content
                )}</div>`
              : this.fixLocalLinks(proximal.content);
          });
      })
    );
  }

  /**
   * get the legal caveats for a particular placement
   */
  getLegals$(placement: string, directs: string[] = []): Observable<string[]> {
    if (this.showDummyCaveats) {
      return of([`Dummy legal caveat for placement: ${placement}`]);
    }
    return this.filteredCaveats$.pipe(
      map((caveats) =>
        caveats.legals
          ?.filter(
            (legal) =>
              legal.placements?.includes(placement) ||
              directs.includes(legal.uuid)
          )
          .sort((a, b) => this.legalProximalCaveatSort(a.priority, b.priority))
          .map((legal) => legal.content)
      )
    );
  }

  getPpssAlerts$(): Observable<Alerts> {
    return this.filteredCaveats$.pipe(map((caveats) => caveats.alerts.ppssMap));
  }

  getFundAlerts$(
    fundId: FundId,
    shareClassCode: ShareClassCode
  ): Observable<Alert[]> {
    return this.filteredCaveats$.pipe(
      map((caveats) => {
        const shareClassAlerts =
          caveats.alerts.alertMap[`${fundId}-${shareClassCode}`];
        const fundAlerts = caveats.alerts.alertMap[fundId];
        if (shareClassAlerts && fundAlerts) {
          shareClassAlerts.concat(fundAlerts);
          return this.caveatAlertPrioritySorting(shareClassAlerts);
        } else if (shareClassAlerts) {
          return this.caveatAlertPrioritySorting(shareClassAlerts);
        } else if (fundAlerts) {
          return this.caveatAlertPrioritySorting(fundAlerts);
        }
        return [];
      })
    );
  }

  // TODO refactor to reuse component mapper code
  private getProductsPresent(
    page: Page,
    productComponents: string[]
  ): string[] {
    const componentNames = [];
    const components: Component[] = page.getComponent().getChildren();
    this.getAnyProductComponentPresent(
      components,
      productComponents,
      componentNames
    );
    return componentNames.filter((i) => i !== undefined);
  }

  private getAnyProductComponentPresent(
    components: any[],
    productComponents: string[],
    componentNames: string[]
  ): void {
    components.forEach((item) => {
      if (productComponents.includes(item.model.label)) {
        // WDE-515 use ctype for components backed by a document instead of a config
        const compName = item.model.models?.config?.componentType
          ? item.model.models.config.componentType
          : item.getType();
        componentNames.push(compName);
      }
      if (item.children.length) {
        this.getAnyProductComponentPresent(
          item.children,
          productComponents,
          componentNames
        );
      }
    });
  }

  initialiseFootnotesByComponent(page: Page, components: string[]) {
    this.footnotesByComponent = { unidentified: [] };
    if (page) {
      this.componentOrder = this.getProductsPresent(page, components);
      for (const comp of this.componentOrder) {
        this.footnotesByComponent[comp] = [];
      }
      this.componentOrder.push('unidentified');
    } else {
      this.componentOrder = ['unidentified'];
    }
  }

  private updateFootnoteOrder(component, placement) {
    if (this.footnotesByComponent[component] === undefined) {
      logger.error(`Unknown component: ${component}`);
      component = 'unidentified';
    }
    this.footnotesByComponent[component].push(placement);
    this.footnoteOrder = this.componentOrder
      .map((comp) => this.footnotesByComponent[comp])
      .flat();
  }

  // TODO: write unit tests to cover pre-existing #fragment trimming
  /**
   *
   * @param content links to, say, #important-legal-info need to have current url path prepended
   */
  private fixLocalLinks(content: string): string {
    // first remove any existing #fragment from page url
    const trimmedUrl: string = this.router.url.replace(/#(.)*/gm, '');
    return content.replace(
      new RegExp(`href=(.)#`, 'gi'),
      `href=$1${trimmedUrl}#`
    );
  }

  /**
   *
   * Fund caveat display in descending order
   */
  private caveatAlertPrioritySorting(caveatArray: Alert[]): Alert[] {
    return caveatArray.sort((a, b) =>
      this.legalProximalCaveatSort(a.priority, b.priority)
    );
  }

  /**
   *
   * Legal proximal caveat display in descending order
   */
  private legalProximalCaveatSort(
    priorityFirst: string,
    prioritySecond: string
  ): number {
    const priorityA = priorityFirst || '';
    const priorityB = prioritySecond || '';
    return Number(priorityB) - Number(priorityA);
  }
}
