/*
 * Copyright 2019 Hippo B.V. (http://www.onehippo.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { AlertService } from '@accounts/services/alert.service';
import {
  AlertObj,
  NotificationDetails,
  SpinnerDetails,
} from '@accounts/accounts-alerts/accounts-alerts.interface';
import { DOCUMENT, Location, ViewportScroller } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Meta, MetaDefinition, Title } from '@angular/platform-browser';
import {
  ActivatedRoute,
  NavigationEnd,
  ResolveStart,
  Router,
} from '@angular/router';
import { Configuration, Page } from '@bloomreach/spa-sdk';
import {
  AnalyticsService,
  CustomDimensions,
  DialogConfig,
  DialogService,
  NavigationService,
  PrimaryNavigationService,
  WindowService,
  SearchServiceToken,
  ResponsiveService,
  CustomOptions,
} from '@frk/eds-components';
import { CartHandlerService } from '@literature/services/cart-handler.service';
import { WINDOW } from '@ng-web-apis/common';
import {
  AnonymousAccess,
  IsGateway,
  PageConfig,
  PageContainerService,
} from '@pages/services/page-container.service';
import { PageThemeService } from '@pages/services/page-theme.service';
import {
  AppStateService,
  AsyncContentManagerService,
  ClassExclusionService,
  IsLoginUser,
  IUserProfile,
  PageMonitor,
  ProfileService,
  RoleSelectorService,
  SchemaService,
  SegmentService,
  SignInService,
  SiteConfigService,
  StorageService,
  WidenService,
  WindowScrollService,
} from '@services';
import { FtSearchService } from '@search/services/ftsearch.service';
import { ComponentMapping } from '@services/module-loader.config';
import {
  PageContainerGridRow,
  LeavingSite,
  SegmentId,
  UtmCampainDetails,
} from '@types';
import {
  ACCOUNTS_DASHBOARD,
  LOGOUT_TRUE,
  MY_CLIENTS,
  NEVER,
  NEVER_BTN,
  NOTNOW_BTN,
  OPEN_BTN,
  RECENT_TRANSACTIONS,
  STATEMENTS,
  TAX_DOCUMENTS,
  UPGRADE_BTN,
  UPGRADE_FLAG,
  VIEW_ACCOUNTS_DASHBOARD,
} from '@utils/app.constants';
import { LabelLoader } from '@utils/label-loader';
import { LabelCollection, Labels } from '@utils/labels';
import { Logger } from '@utils/logger';
import { checkLink } from '@utils/link-utils';
import { NgxSpinnerService } from 'ngx-spinner';
import { combineLatest, Subject, Observable, timer } from 'rxjs';
import {
  delay,
  distinctUntilChanged,
  filter,
  map,
  startWith,
  take,
  takeUntil,
} from 'rxjs/operators';
import { removeParam } from '@utils/link-utils';
import { StickyFooterService } from '@services/sticky-footer.service';
import { SkiptoHeaderService } from '@services/skipto-header.service';
import { ViewModeService } from '@services/view-mode.service';

const logger = Logger.getLogger('PageContainerComponent');

declare global {
  interface Window {
    appInitChannelConfig: { basepath: string; defaultSegment?: SegmentId };
    OneTrust: any;
    MktoForms2: any;
    rtp: any;
  }

  interface PageAnalyticsMetadata {
    assetClass: [];
    fundBrand: [];
    investmentTeam: [];
    investmentTheme: [];
  }

  interface AnalyticsPageData {
    title?: string;
    pageType?: string;
    language?: string;
    analyticsMetadata?: PageAnalyticsMetadata;
  }

  interface PageDocument {
    model: {
      data: {
        contentType?: string;
        gridLayout?: PageContainerGridRow[];
        pageMetadata?: PageMetaData;
      };
    };
  }
}

interface PageMetaData {
  name: string;
  pageType: string;
  headerStyle: string;
  footerStyle: string;
  campaignPageTagging: string;
}

/**
 * Page Container
 */
@Component({
  selector: 'page-container',
  templateUrl: './page-container.component.html',
  styleUrls: ['./page-container.component.scss'],
  providers: [LabelLoader],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageContainerComponent
  implements OnDestroy, OnInit, AfterViewInit {
  /**
   * External link dialog config
   */
  public dialogConfig: DialogConfig;
  public dialogConfigCustom: DialogConfig;

  /**
   * Configuration
   */
  public configuration: Configuration;

  /**
   * Bloomreach page object
   */
  public page: Page;

  /**
   * Bloomreach page root document
   */
  public pageDocument: PageDocument;

  /**
   * Bloomreach Component Mapping
   */
  public mapping: ComponentMapping;

  /**
   * Layout name is used to determine which component to load
   */
  public layoutName: string;

  private pageType: string;
  /**
   * used to remove subscriptions when component is destroyed
   */
  private unsubscribe$: Subject<void> = new Subject<void>();
  private unsubscribeTimer$: Subject<void> = new Subject<void>();

  /**
   * Requested url
   */
  private requestUrl: string;

  private percentageCounter: number;

  private fileConversion: boolean;

  public isTemit: boolean;

  private layoutsWithCustomPageViewEvent = ['product-detail', 'insight'];

  public notificationDetails: NotificationDetails;
  public siteAlertDetails: NotificationDetails[];

  private utmUrl: string;

  private utmValue: Partial<UtmCampainDetails>;

  // current url #fragment
  private fragment: string;

  private previousUrl: string;

  private oneMinuteSpentOnPage: boolean;
  // Map to track event has been fired for the specific scroll
  public scrollEventFired: Map<string, boolean> = new Map(
    Object.entries({ 50: false, 75: false })
  );

  private hasScrolled50Percent = false;
  private hasScrolled75Percent = false;
  private isScrollingBackFrom75 = false;
  /**
   * Spinner Details
   */
  public spinnerDetails: SpinnerDetails;

  public segmentOk = false;

  private isPageReady$: Observable<boolean>;

  /**
   * Back To Top button
   */
  showButtons = false;
  pageHeight: number;
  hasScrollToFooter: boolean;
  stickyBackToTopButton: number;
  hasBackToTopButton = false;
  isMobile = false;
  extraPadding = false;
  paddingOnClick: boolean;
  mobilePadding: boolean;
  private isRoleSelectorTrigger: boolean;
  private targetElement: Element;
  /**
   * Configuration for new grid component
   */
  gridLayouts: PageContainerGridRow[];
  @ViewChild('footerScroll') footerScroll: ElementRef;
  @ViewChild('backToTopLink') backToTopLink: ElementRef;

  @ViewChild('mainContent') mainContent: ElementRef;

  isEditMode = false;
  isExternalLayout = false;

  /**
   * Constructor
   */
  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private analytics: AnalyticsService,
    private router: Router,
    private loc: Location,
    private spinner: NgxSpinnerService,
    private dialogService: DialogService,
    private navService: NavigationService,
    private meta: Meta,
    private title: Title,
    private activatedRoute: ActivatedRoute,
    private accountsAlertService: AlertService,
    private pageContainerService: PageContainerService,
    private primaryNavService: PrimaryNavigationService,
    private profileService: ProfileService,
    private siteConfigService: SiteConfigService,
    private classExclusionService: ClassExclusionService,
    private cartService: CartHandlerService,
    private storageService: StorageService,
    private widenService: WidenService,
    private schemaService: SchemaService,
    private pageThemeService: PageThemeService,
    private signInService: SignInService,
    private windowService: WindowService,
    private acm: AsyncContentManagerService,
    private scrollService: WindowScrollService,
    private segmentService: SegmentService,
    private responsiveService: ResponsiveService,
    private stickyFooterService: StickyFooterService,
    private roleSelectorService: RoleSelectorService,
    private skipToHeaderService: SkiptoHeaderService,
    private viewModeService: ViewModeService,
    private appStateService: AppStateService,
    @Inject(DOCUMENT) private documentRef: Document,
    @Inject(WINDOW) readonly windowRef: Window,
    @Inject(SearchServiceToken) private searchService: FtSearchService,
    private viewportScroller: ViewportScroller
  ) {
    this.isEditMode = this.viewModeService.isEditMode();
    // Remove Marketo RTP cookies if RTP code is loaded through GTM on production
    if (
      this.windowRef.rtp !== null &&
      typeof this.windowRef.rtp !== 'undefined'
    ) {
      this.storageService.setCheckCookie(true);
      this.storageService.remove('trwv.uid');
      this.storageService.remove('trwsa.sid');
      this.storageService.setCheckCookie(false);
    }

    this.isPageReady$ = this.acm.isPageReady$();

    this.requestUrl = this.loc.path();

    this.isTemit = this.siteConfigService.isTemit();

    // subscribe to url #fragment changes
    this.activatedRoute.fragment
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((fragment: string): void => {
        this.fragment = fragment;
      });

    this.pageContainerService.page$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((pageConfig: PageConfig) => {
        this.mapping = pageConfig.mapping;
        this.page = pageConfig.page;
        this.pageDocument = this.page.getDocument<PageDocument>();
        this.gridLayouts = this.pageDocument?.model?.data?.gridLayout;
        if (this.gridLayouts) {
          // Set CSS Classes
          for (const gridLayout of this.gridLayouts) {
            if (gridLayout.layout) {
              gridLayout.cssClass = this.getCSSClass(gridLayout.layout);
            }
          }
        }
        this.isExternalLayout = this.isLayoutExternal();
        this.segmentOk = this.isEditMode || pageConfig.segmentOk;
        // redirect to servicing maintenance page
        if (
          this.siteConfigService?.common?.servicingMaintenance &&
          this.page
            ?.getComponent()
            ?.getModels()
            ?.pageData?.pageType.includes('us-servicing')
        ) {
          return this.navService.navigateByUrl('/servicing-maintenance');
        }
        if (
          !this.segmentOk &&
          pageConfig?.anonymousAccess === AnonymousAccess.ATTESTED
        ) {
          logger.debug('Page with attested access');
          this.anonymousAttestedAccess();
        }
        if (
          !this.segmentOk &&
          pageConfig?.anonymousAccess === AnonymousAccess.ALLOWED
        ) {
          logger.debug('Page allowed for anonymous users');
          if (pageConfig.allowedSegment) {
            this.anonymousAccess(pageConfig.allowedSegment);
          }
        }

        if (this.isEditMode) {
          // remove one trust consent form in preview
          this.documentRef.getElementById('onetrust-consent-sdk')?.remove();
        }
        this.configuration = pageConfig.configuration;

        this.acm.registerComponents(this.page);

        // Trigger Page view
        const params = this.page.getComponent().getParameters();
        this.layoutName = params?.layout;
        if (this.layoutName === undefined) {
          this.layoutName = 'twocols';
        }
        this.pageType = params?.pageType;

        if (!this.dialogConfig) {
          this.initDialogConfig();
        }

        this.pageThemeService.setPageTheme(this.page);

        this.setMetaData(this.page);
        // Schema Template
        this.setJsonLdSchema();
        this.spinner.hide('pageLoadSpinner');
        this.primaryNavService.closeMegaMenu();
        this.changeDetectorRef.detectChanges();

        // this needs to be tested carefully
        // It seems, that page loader/spinner is still active when we change prerenderReady to true
        // we may detect if loader is hidden before setting this property to true
        this.isPageReady$
          .pipe(
            takeUntil(this.unsubscribe$),
            filter((state) => state === true),
            // after components are ready we need to wait additionall 1 seconds to make sure content is rendered
            // previous solution was always waiting 10 seconds
            // without this pages were indexed without navigation in a header
            delay(1000)
          )
          .subscribe(() => {
            (this.windowRef as any).prerenderReady = true;
            logger.debug('Setting prerenderReady=true');
          });

        /**
         * Set active primary navigation menu item
         * Adding this.windowRef.location.search to compare query parameters along with URL if present
         * e.g. http://<environment>/investments/options/mutual-funds?filter=SHARE_CLASS_NAME:Class%20A;
         */
        this.primaryNavService.setActivePrimaryNav(
          this.windowRef.location.pathname + this.windowRef.location.search
        );

        /**
         * If hash is present in URL
         * -- creates scroll request to be actioned once data+images have loaded
         */
        if (this.fragment && this.windowRef.location.hash !== '') {
          logger.debug('hash value', this.fragment);
          this.scrollService.scrollToAnchor(this.fragment);
        }
      });

    // Nav start
    // WARNING: it's important this uses ResolveStart instead of NavigationStart, so canDeactivate Guard runs before it
    this.router.events
      .pipe(
        takeUntil(this.unsubscribe$),
        filter((event) => event instanceof ResolveStart),
        // remove fragment from url, so we can filter out this change
        map((event: ResolveStart): string => event.url.split('#')[0]),
        // need startWith() because this subscribes after initial NavigationStart event
        // NB: startWith() isn't deprecated if your IDE complains:
        // https://stackoverflow.com/questions/56571406/is-the-startwith-operator-in-rxjs-really-deprecated
        startWith(this.router.routerState?.snapshot.url.split('#')[0]),
        map((url: string) => (url ? this.removeParam(url, 'query') : '')),
        map((url: string) => (url ? this.removeParam(url, 'queryType') : '')),
        map((url: string) => (url ? this.removeParam(url, 'filter') : '')),
        // distinctUntilChanged() means this only fires for route changes - not fragment changes
        distinctUntilChanged()
      )
      .subscribe((url) => {
        this.previousUrl = this.router.routerState?.snapshot?.url;

        PageMonitor.getMonitor().newPage(url);
        logger.debug('Show Spinner', url);
        // Show spinner
        this.spinner.show('pageLoadSpinner');
        // set to 0 when its navigation to new page
        this.percentageCounter = 0;
        // set to false when its navigation to new page
        this.oneMinuteSpentOnPage = false;
        // start countdownforminute when its navigation to new page
        this.startCountdownForMinute();
        // Clear scrollEvent when its navigation to new page
        this.scrollEventFired.clear();
        // Initilizing the scroll variables.
        this.hasScrolled50Percent = false;
        this.hasScrolled75Percent = false;
        this.isScrollingBackFrom75 = false;
        // Hide the Logout Alert for next page redirect
        this.accountsAlertService.showAccountsAlert = false;
      });

    // Observe navigation end and get params for utm Campaign tracking.
    this.router.events
      .pipe(
        takeUntil(this.unsubscribe$),
        filter((event) => event instanceof NavigationEnd)
      )
      .subscribe((event: NavigationEnd) => {
        // #fragment now set from activatedRoute
        // if (event.url.includes('#')) {
        //   this.urlHashValue = event.url.split('#')[1];
        // }
        // reloading the page if the url is present in redirect urls
        // akamai will redirect to the new app
        if (this.isRedirectUrlToNewApp(event)) {
          this.windowRef.location.reload();
        }
        if (event.url.includes('utm_')) {
          this.utmUrl = event.url;
        }

        /**
         * Set custom variables and send to Marketo to load respective campaigns based on user type
         * we can setup max 5 custom variables to Marketo namely
         * customVar1 - set to userType
         * customVar2 - available
         * customVar3 - available
         * customVar4 - available
         * customVar5 - available
         */

        this.segmentService
          .getCurrentSegment$()
          .pipe(takeUntil(this.unsubscribe$), delay(1000))
          .subscribe((segment) => {
            if (
              this.windowRef.rtp !== null &&
              typeof this.windowRef.rtp !== 'undefined'
            ) {
              this.storageService.setCheckCookie(true);
              this.storageService.remove('trwv.uid');
              this.storageService.remove('trwsa.sid');
              this.storageService.setCheckCookie(false);
              // Set custom variable 1 to user type
              this.windowRef.rtp('set', 'customVar1', segment?.id);
              this.windowRef.rtp('send', 'view');
              this.windowRef.rtp('get', 'campaign', true);
            }
            this.changeDetectorRef.detectChanges();
          });
      });

    combineLatest([
      this.pageContainerService.page$.pipe(
        map((pageConfig) => pageConfig.page.getUrl()),
        distinctUntilChanged()
      ),
      this.profileService.getUserProfile$(),
    ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([page, profile]) => {
        logger.debug('Logger Profile for analytics', page, profile);
        if (
          // we are using pagetype as a switch to filter pageview Event.
          // product-detail.component and article.component fire their own pageview events.
          // for us servicing pages check
          !this.layoutsWithCustomPageViewEvent.includes(this.layoutName) &&
          !this.layoutsWithCustomPageViewEvent.includes(this.pageType) &&
          !this.page
            .getComponent()
            ?.getModels()
            ?.pageData?.pageType?.includes('us-servicing')
        ) {
          this.triggerPageView();
        }
      });

    // Observe navigation end
    this.router.events
      .pipe(
        takeUntil(this.unsubscribe$),
        filter((event) => event instanceof NavigationEnd),
        // removes # fragment so we can ignore in-page links
        map((event: NavigationEnd): string => event.url.split('#')[0]),
        // ignores parameter 'query' which is changed from search component
        // we don't need to load new page when query string is changing when search term is changed
        map((url: string) => (url ? this.removeParam(url, 'query') : '')),
        map((url: string) => (url ? this.removeParam(url, 'queryType') : '')),
        map((url: string) => (url ? this.removeParam(url, 'filter') : '')),
        distinctUntilChanged()
      )
      .subscribe((url: string) => {
        logger.debug('NavigationEnd', url);
        this.pageContainerService.newPage(url);

        if (!this.windowService.isPageTop()) {
          this.scrollService.scrollToTop();
        }
      });

    // Get alerts and send to alert components
    this.accountsAlertService
      .getAccountsAlert$()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((notificationData: NotificationDetails) => {
        this.notificationDetails = notificationData;
      });
    this.signInService
      .getSpinner$()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((spinnerDetails: SpinnerDetails) => {
        this.spinnerDetails = spinnerDetails;
        if (this.spinnerDetails.spinnerStatus) {
          this.spinner.show(this.spinnerDetails.spinnerName);
        } else {
          this.spinner.hide(this.spinnerDetails.spinnerName);
        }
      });

    /**
     * Set custom variables and send to Marketo to load respective campaigns based on user type
     * we can setup max 5 custom variables to Marketo namely
     * customVar1 - set to userType
     * customVar2 - available
     * customVar3 - available
     * customVar4 - available
     * customVar5 - available
     */

    this.segmentService
      .getCurrentSegment$()
      .pipe(takeUntil(this.unsubscribe$), delay(1000))
      .subscribe((segment) => {
        if (
          this.windowRef.rtp !== null &&
          typeof this.windowRef.rtp !== 'undefined'
        ) {
          this.storageService.setCheckCookie(true);
          this.storageService.remove('trwv.uid');
          this.storageService.remove('trwsa.sid');
          this.storageService.setCheckCookie(false);

          // Set custom variable 1 to user type
          this.windowRef.rtp('set', 'customVar1', segment?.id);
          this.windowRef.rtp('send', 'view');
          this.windowRef.rtp('get', 'campaign', true);
        }
        this.changeDetectorRef.detectChanges();
      });

    // Track Analytics for Print
    this.windowService
      .getBeforePrint$() // Track Analytics BEFORE the user tries to print
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => this.trackPrintAnalytics());
  }

  /**
   * Page loaded
   */
  public ngAfterViewInit(): void {
    this.fileConversion = false;
    if (this.utmUrl !== undefined) {
      this.getUtmUrl();
    }

    // Track Window Scroll Analytics (Throttled)
    // TODO: do we really need to run this 20 times a second?
    this.windowService
      .getScrollObs$(50) // Throttle time 50ms to track if a user scrolls very fast
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => this.trackScrollAnalytics());

    this.listenToSearchOpenClose();

    // Subscribe to the boolean value emitted by skipToHeaderService
    this.skipToHeaderService
      .getSkipToHeaderBoolean$()
      // Filter out falsy values emitted by skipToHeaderService
      .pipe(
        takeUntil(this.unsubscribe$),
        filter((skipTo) => skipTo)
      )
      // Perform the focus and scrollIntoView actions when a truthy value is emitted by skipToHeaderService
      .subscribe(() => {
        this.skipToHeaderService.scrollToMainContent(this.mainContent);
      });
  }

  private isRedirectUrlToNewApp = (event: NavigationEnd): boolean =>
    this.appStateService
      .getRedirectUrlsToNewApp()
      .some(
        (redirectUrl) =>
          !this.previousUrl.includes(redirectUrl) &&
          event.url.includes(redirectUrl)
      ) && this.windowRef.location.origin.includes('staging2');

  // hiding page content when search opened
  private listenToSearchOpenClose() {
    combineLatest([this.isPageReady$, this.searchService.searchModalStatus$])
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(([ready, state]) => ready === true && state === true),
        delay(600)
      )
      .subscribe(() => {
        this.documentRef
          .getElementsByTagName('main')?.[0]
          ?.classList?.add('hidden');
        this.documentRef
          .getElementsByTagName('ft-site-alert')?.[0]
          ?.classList?.add('hidden');
      });

    combineLatest([this.isPageReady$, this.searchService.searchModalStatus$])
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(([ready, state]) => ready === true && state === false)
      )
      .subscribe(() => {
        this.documentRef
          .getElementsByTagName('main')?.[0]
          ?.classList?.remove('hidden');
        this.documentRef
          .getElementsByTagName('ft-site-alert')?.[0]
          ?.classList?.remove('hidden');
      });
  }

  private checkSignIn(event) {
    const target = event.target as HTMLElement;
    if (
      (target.closest('[data-signin-required=true]') ||
        target.dataset?.signinRequired?.toLowerCase?.() === 'true') &&
      !this.profileService.isLoggedIn()
    ) {
      event.preventDefault();
      event.stopPropagation();
      if (!this.appStateService.isAuth0Login()) {
        this.signInService.showSignInModal();
        const introText: string = target
          .closest('[data-signin-custom-intro]')
          ?.getAttribute('data-signin-custom-intro');
        // Set introText if configured or reset to default 'signin.introText'
        this.signInService.showCustomIntroTxtInModal(
          introText || 'signin.introText'
        );
        const bodyContent: string = target
          .closest('[data-signin-custom-content-text]')
          ?.getAttribute('data-signin-custom-content-text');
        // Set bodyContent if configured or reset to default 'signin.bodyContent'
        this.signInService.showCustomContentModalModal(
          bodyContent || 'signin.bodyContent'
        );
      } else {
        this.signInService.submitAuth0();
      }
    }
  }
  /**
   * On initialisation
   */
  public ngOnInit(): void {
    this.replaceRouteState();

    // Init the dialog for external links
    this.initExternalDialog();

    this.responsiveService
      .isMobile$()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((isMobileDevice) => {
        this.isMobile = isMobileDevice;
      });

    this.windowService
      .getScrollObs$(100)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        const windowScroll = this.windowRef.scrollY;
        this.pageHeight = this.windowRef.innerHeight;
        const offsetDifference =
          this.windowRef.scrollY - this.footerScroll?.nativeElement.offsetTop;
        this.stickyBackToTopButton =
          offsetDifference > 0 ? offsetDifference : 0;
        // Button is visible after scrolling a length equivalent to 2 pages.
        this.showButtons = windowScroll > this.pageHeight * 2;
        if (
          this.windowRef.innerHeight + this.windowRef.scrollY >=
          this.documentRef.body.scrollHeight - 530
        ) {
          // you're at the bottom of the page
          this.hasScrollToFooter = true;
          this.extraPadding = false;
        } else {
          this.hasScrollToFooter = false;
          if (this.paddingOnClick) {
            this.extraPadding = true;
          } else {
            this.extraPadding = false;
          }
        }
        this.changeDetectorRef.detectChanges();
      });

    this.stickyFooterService
      .getFooterStatus$()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((value) => {
        this.extraPadding = value;
        this.paddingOnClick = value;
      });
    this.stickyFooterService
      .getFooterMobileStatus$()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((value) => {
        this.mobilePadding = value;
      });
  }

  private setJsonLdSchema(): void {
    const url = this.router.url;
    const title = this.page.getComponent()?.getModels()?.pageData?.title;
    this.schemaService.insertSchema(
      SchemaService.websiteSchema(url, title),
      'structured-data'
    );
    /*
       Register to Angular navigation events to detect navigating away
    */
    this.router.events
      .pipe(
        takeUntil(this.unsubscribe$),
        filter((event) => event instanceof NavigationEnd)
      )
      .subscribe(() => {
        // update the json-ld tag in head
        this.schemaService.insertSchema(
          SchemaService.websiteSchema(url, title),
          'structured-data'
        );
      });
  }

  /**
   * On component destroyed
   * Removes any remaining subscriptions
   */
  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.unsubscribeFromTimer();
  }

  private unsubscribeFromTimer(): void {
    this.unsubscribeTimer$.next();
    this.unsubscribeTimer$.complete();
  }

  // get URLprams for utm Campaign tracking
  private getUtmUrl(): void {
    const utmTags = [];
    let hash = [];
    const hashes = this.utmUrl.slice(this.utmUrl.indexOf('?') + 1).split('&');
    hashes.forEach((item) => {
      hash = item.split('=');
      utmTags.push([...hash]);
    });
    this.utmValue = Object.fromEntries(utmTags);
  }

  /**
   * Replaces the route state in case of bypass or logout route
   */
  private replaceRouteState(): void {
    const url = this.router.url;
    // TODO: replace with Angular query param map: https://angular.io/guide/router-tutorial-toh#query-parameters
    const urlSplit = url?.split('?');
    if (url && urlSplit.length >= 2 && urlSplit[1].includes(LOGOUT_TRUE)) {
      // Clear the literature cart details in session time out
      this.cartService.emptyCartStorage();
      // register alerts
      this.activatedRoute.queryParams
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((params: AlertObj) => {
          this.accountsAlertService.setAccountsAlert(params);
        });
      this.loc.replaceState(urlSplit[0]);
    }
  }

  /**
   * Init External Dialog
   */
  private initExternalDialog(): void {
    // Shared dialog
    if (this.dialogService) {
      // Listen for dialog confirmation
      this.dialogService
        ?.getDialogClosedObs()
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((dialog) => {
          if (dialog) {
            if (dialog?.dialogId?.includes('external-link')) {
              // fire lightboxevent on external-link modal
              this.analytics.trackEvent({
                event: 'lightbox_display',
                action: 'click',
                label: dialog?.config?.link_url,
              });

              this.analytics.trackEvent({
                event: 'lightbox_impression',
                detailed_event: 'Lightbox Impression',
                event_data: {
                  display_type: 'click',
                  heading: dialog?.config?.link_url,
                },
              });

              // Open external link in "target" tab
              if (dialog?.config?.successUrl) {
                if (dialog?.config?.eventName) {
                  const cookieName: string = this.profileService.getToolsTermsCookie(
                    dialog?.config
                  );
                  this.storageService.store(cookieName, true);
                }
                const targetWindow = dialog?.config?.targetWindow || '_self';
                this.windowRef.open(dialog?.config?.successUrl, targetWindow);
              }
            }
          }
        });
    }
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent): void {
    // functionaliity to enable shift tab behafor for anchors on whose

    if (event.target instanceof HTMLAnchorElement) {
      if (event.key === 'Tab' && !event.shiftKey) {
        this.targetElement = this.documentRef.activeElement;

        if (this.targetElement.id === 'active-element-focus') {
          this.targetElement.removeAttribute('id');
        }
      }
      if (event.key === 'Enter') {
        this.targetElement = this.documentRef.activeElement;

        if (!this.targetElement?.id) {
          this.targetElement.id = 'active-element-focus';
        }
      }
      if (
        event.shiftKey === true &&
        event.key === 'Tab' &&
        this.documentRef.getElementById(this.targetElement?.id)
      ) {
        event.preventDefault();
        // focus back to last selected element
        this.documentRef.getElementById(this.targetElement?.id).focus();
        // scroll back to last selected element
        this.viewportScroller.scrollToAnchor(this.targetElement.id);
        if (this.targetElement.id === 'active-element-focus') {
          this.targetElement.removeAttribute('id');
        }
        this.targetElement = null;
        this.documentRef?.getElementById('footnote-back-btn')?.remove();
      }
    }
  }

  /**
   * Rich text links need handled globally to change them
   * to route correctly.
   *
   */
  @HostListener('click', ['$event'])
  onClick(event: MouseEvent): boolean {
    // One trust hook
    // logger.debug('click MouseEvent', event)

    const target: EventTarget = checkLink(event);
    // functionaliity to open sign in modal for the link|button that have signin-required true

    if (
      target instanceof HTMLAnchorElement ||
      target instanceof HTMLButtonElement
    ) {
      this.checkSignIn(event);
      const classList: DOMTokenList = target.classList;
      if (classList.contains('ot-sdk-show-settings')) {
        // Launch preferences
        this.showCookieSettings();
        return false;
      }
      if (classList.value.includes('modal-close')) {
        logger.debug('Close modal event - do not track here');
        // Close modal button is tracked separately in EDS. It should not be tracked additionaly as 'linkOrButtonClick'
        return false;
      }
      // Handle click event
      // Track analytics
      let linkText: string = target.textContent;
      if (!target.textContent) {
        linkText = this.checkImageAlt(event);
      }

      // We need to track events when button is only eds-ICON
      if (!linkText && target?.firstElementChild?.tagName === 'EDS-ICON') {
        linkText = target?.firstElementChild?.getAttribute('type');
      }

      const hasPrivacyMask: boolean = target?.classList?.value?.includes(
        'dd-privacy-mask'
      );

      linkText = hasPrivacyMask ? '[privacy mask]' : linkText?.trim();

      let linkUrl = '';
      let linkTrackDownload = false;
      let linkTrackImpression = false;

      if (target instanceof HTMLAnchorElement) {
        linkUrl = target.href;
        linkTrackDownload = target.dataset?.analyticsTrackDownload === 'true';
        linkTrackImpression =
          target.dataset?.analyticsTrackImpression === 'true';
      }

      if (classList.value.includes('external-link--')) {
        logger.debug('getting custom content for leaving site');
        this.initDialogConfig(this.setExternalModalCustom(classList));
        this.dialogConfigCustom.successUrl = linkUrl;
        this.dialogConfigCustom.targetWindow = target.getAttribute('target');
        const trackConf: DialogConfig = {
          link_text: target.innerText,
          link_url: linkUrl,
          successUrl: linkUrl,
          targetWindow: this.dialogConfigCustom.targetWindow,
        };
        const cookieName: string = this.profileService.getToolsTermsCookie(
          this.dialogConfigCustom
        );
        this.storageService.retrieveToolsCookie(cookieName).then((isTools) => {
          if (!isTools) {
            event.preventDefault();
            this.dialogService.open('external-link-custom', trackConf, target);
          }
        });
      }

      if (classList.value.includes('external-link')) {
        this.analytics.trackEvent({
          event: 'lightbox_display',
          action: 'click',
          link_text: linkText,
          link_url: linkUrl,
          label: linkText,
        });

        this.analytics.trackEvent({
          event: 'lightbox_impression',
          detailed_event: 'Lightbox Impression',
          event_data: {
            display_type: 'click',
            heading: linkText,
          },
        });
      }

      // tracking for pre-search links on search module
      const { analyticsQuickLink } = target.dataset;
      if (analyticsQuickLink) {
        this.analytics.trackEvent({
          event: 'search',
          search_category: 'Quick Link',
          search_term: target.innerText,
        });
      }

      // default event name for clicks tracking defined
      let eventName = 'linkOrButtonClick';

      // Check if the target element is for deleting a filter
      const elemChildNodes = target.children[0];
      if (elemChildNodes) {
        Array.from(elemChildNodes.children).forEach((child) => {
          if (child.className === 'analytics-reset') {
            // sets event as form_filter in analytics when filter is being deleted
            eventName = 'form_filter';
          }
        });
      }

      const resetFilterElement = target.parentNode;
      let resetFlag = false;
      // sets event as form_filter in analytics if filter(s) are being reset
      if (resetFilterElement) {
        Array.from(resetFilterElement.children).forEach((child) => {
          if (child.className.includes('analytics-reset')) {
            // sets event as form_filter in analytics when filter is being deleted
            resetFlag = true;
          }
        });
      }

      // Preventing linkOrButtonClick event from firing on download and removal of chips on filters
      const { analyticsNoEvent, id } = target.dataset;
      const mrktoform = target.parentNode?.parentElement?.className.includes(
        'mkto'
      );
      if (
        !mrktoform &&
        this.classExclusionService.classExclusion(classList) &&
        !linkUrl.includes('widen.net') &&
        !linkUrl.includes('.pdf') &&
        !linkTrackDownload &&
        analyticsNoEvent !== 'true' &&
        !resetFlag &&
        // Disable Tracking event from Brightcove video player's embedded play/pause buttons
        !(linkText === 'Play' || linkText === 'Pause') &&
        !linkUrl.includes('mailto') &&
        !id?.includes('skip-linkorbutton-tracking')
      ) {
        // for linkOrButtonClick we send combined GA4/UA event below
        if (eventName !== 'linkOrButtonClick') {
          this.analytics.trackEvent({
            event: eventName,
            link_text: linkText,
            link_url: linkUrl,
          });
        }
        if (eventName === 'linkOrButtonClick') {
          const isOutbound = target?.classList?.value?.includes(
            'external-link'
          );
          const tabType: string = target.getAttribute('data-tab_type');

          this.analytics.trackEvent({
            event: eventName,
            link_text: linkText,
            link_url: linkUrl,
            detailed_event: 'Link or Button Click',
            event_data: {
              category: '',
              link_id: '',
              link_text: linkText,
              link_type: this.analytics.getLinkType(linkUrl, isOutbound),
              link_url: linkUrl,
              tab_type: tabType,
            },
          });
        }
      }

      // Track analytics events when link with pdf is clicked.
      if (linkUrl.includes('widen.net') || linkTrackDownload) {
        // Set event name to file_download
        const fileName = linkUrl.substring(linkUrl.lastIndexOf('/') + 1);

        this.analytics.trackEvent({
          event: 'file_download',
          detailed_event: 'Download Link Clicked',
          link_text: linkText,
          file_name: fileName,
          link_url: linkUrl,
          embed_id: target?.dataset?.externalId || '',
          document_title: target?.dataset?.documentTitle || '',
          document_source: 'Widen',
          event_data: {
            embed_id: target?.dataset?.externalId || '',
            file_extension: '',
            file_lit_code: '',
            file_name: fileName,
            link_url: linkUrl,
            location: '',
            document_title: target?.dataset?.documentTitle || '',
            document_source: 'Widen',
          },
        });
      }

      // Track analytics events when Email button is clicked.
      if (linkUrl.includes('mailto')) {
        // Set event name to contact_link
        this.analytics.trackEvent({
          event: 'contact_link',
          method: 'email',
          label: linkUrl,
        });
        this.analytics.trackEvent({
          event: 'contact',
          detailed_event: 'Contact',
          action: '',
          event_data: {
            contact_action: linkText,
            method: 'email',
          },
          method: 'email',
        });
      }
      // track impression
      this.trackFileExtDownload(linkUrl, linkTrackImpression);

      /*
        TODO:
        existing code assumes that EVERY button or link click should trigger navigation.
        that assumption is wrong, but removing it may cause bugs
        Until this is properly refactored, quick fix of adding data-cancel-navigation attribute
        e.g. <a href="..." data-cancel-navigation>...</a>
        will stop automatic navigation call
      */
      if (
        !('cancelNavigation' in target.dataset) &&
        !('signinRequired' in target.dataset)
      ) {
        // logger.debug('navService.navigate()', event)
        // Handle global click events in a service
        return this.navService.navigate(event);
      }
    }
  }

  /**
   * Track Print Events
   */
  private trackPrintAnalytics(): void {
    const routeUrl = this.getRouteUrl();
    this.analytics.trackEvent({
      event: 'printEvent',
      detailed_event: 'Print Click',
      label: routeUrl,
      action: 'Print Preview',
      event_data: {
        action: 'Print Preview',
        label: routeUrl,
        category: '',
        link_id: '',
        link_text: 'Print',
        link_type: this.analytics.getLinkType(''),
        link_url: routeUrl,
      },
    });
  }

  /**
   * Called by throttled window scroll events
   */
  // TODO: investigate if this is causing unnecessary change detection to run when scrolling
  private trackScrollAnalytics(): void {
    const scroll = this.getScrollPercent();
    // Scrolling fires analytics only on scroll down. When counter reach 100 analytics is registered.
    // Runs analytics when page scroll reach 25%
    if (scroll >= 25 && scroll < 50 && this.counterCheck(0)) {
      this.percentageCounter = 25;
      this.analyticScroll([this.percentageCounter]);
    }
    // Runs analytics when page scroll reach 50%
    if (scroll >= 50 && scroll < 75 && this.counterCheck(25)) {
      if (this.percentageCounter === 0) {
        this.analyticScroll([25]);
      }
      this.percentageCounter = 50;
      this.hasScrolled50Percent = true;
      this.analyticScroll([this.percentageCounter]);
    }

    if (scroll >= 75 && scroll < 100) {
      this.hasScrolled75Percent = true;
    }

    // To track scroll back from 75 when read_content event already fired on 75 & 1 minute.
    if (scroll < 75 && this.hasScrolled75Percent) {
      this.isScrollingBackFrom75 = true;
    }

    this.sendDataAnalyticsEventForTimeOnPageAndScroll();

    // Runs analytics when page scroll reach 75%
    if (scroll >= 75 && scroll < 100 && this.counterCheck(50)) {
      if (this.percentageCounter === 0) {
        this.analyticScroll([25, 50]);
      }
      this.percentageCounter = 75;
      this.analyticScroll([this.percentageCounter]);
    }
    // Runs analytics when page scroll reach 100%
    if (scroll === 100 && this.counterCheck(75)) {
      if (this.percentageCounter === 0) {
        this.analyticScroll([25, 50, 75]);
      }
      this.percentageCounter = 100;
      this.analyticScroll([this.percentageCounter]);
    }
  }

  private track1MinuteScroll(scroll: number): void {
    const eventObj: CustomOptions = {
      event: 'read_content',
      action: `1 Minute and ${scroll}% Scroll`,
    };

    // GA4
    if (scroll === 75) {
      eventObj.detailed_event = 'Read Content';
      eventObj.event_data = {
        page_engagement_type: `1 Minute and ${scroll}% Scroll`,
      };
    }
    this.analytics.trackEvent(eventObj);
    this.scrollEventFired.set('' + scroll, true);
  }

  /**
   * Start a countdown for 1 minute
   */
  private startCountdownForMinute(): void {
    const timeInMilliSecs = 60000;
    timer(timeInMilliSecs)
      .pipe(takeUntil(this.unsubscribeTimer$))
      .subscribe(
        () => {
          this.oneMinuteSpentOnPage = true;
          this.sendDataAnalyticsEventForTimeOnPageAndScroll();
        },
        () => {},
        () => {
          this.unsubscribeFromTimer();
        }
      );
  }
  // Method to check scroll event when user is completed 1 minute on page.
  public checkScrollFor1Minute(
    scrollPoint: string,
    OneMinuteSpent: boolean,
    hasScroll50: boolean,
    hasScroll75: boolean,
    isScrollingBackFrom75: boolean
  ): boolean {
    // Returns false when read_content already fired for the scroll points(50 & 75) OR one minute is complete.
    if (this.scrollEventFired.get(scrollPoint) || !OneMinuteSpent) {
      return false;
    }

    /*
    // Returns true in Below secenarios:
    // 1. If scroll point to check is 50 and user scrolled to 50 and not scrolled to 75
    // 2. If scroll point to check is 50 and user scrolled to 50 and scrolled to 75 and scrolling back from  75.
    // 3. If scroll point to check is 75 and user scrolled to 75
    */
    return (
      (scrollPoint === '50' &&
        hasScroll50 &&
        (!hasScroll75 || (hasScroll75 && isScrollingBackFrom75))) ||
      (scrollPoint === '75' && hasScroll75)
    );
  }

  private sendDataAnalyticsEventForTimeOnPageAndScroll(): void {
    // Runs analytics when user has spent 1 minute on a page
    // and scrolls to either 50% or 75%, only once.
    if (
      this.checkScrollFor1Minute(
        '50',
        this.oneMinuteSpentOnPage,
        this.hasScrolled50Percent,
        this.hasScrolled75Percent,
        this.isScrollingBackFrom75
      )
    ) {
      this.track1MinuteScroll(50);
    }

    if (
      this.checkScrollFor1Minute(
        '75',
        this.oneMinuteSpentOnPage,
        this.hasScrolled50Percent,
        this.hasScrolled75Percent,
        this.isScrollingBackFrom75
      )
    ) {
      this.track1MinuteScroll(75);
    }
  }

  /**
   * Track for documents fileConversion
   * @param linkUrl - string with clicked link
   */
  private trackFileExtDownload(
    linkUrl: string,
    trackImpression: boolean
  ): void {
    // This covers PDF files extensions. It might be extended for other files eg. XLS, XSLS, DOC, DOCX
    if (
      (linkUrl.indexOf('.pdf') !== -1 || trackImpression) &&
      !this.fileConversion
    ) {
      // logger.debug('Tracking file_conversion - download');
      this.analytics.trackEvent({
        event: 'file_conversion',
        interaction_type: 'download',
        file_extension: 'pdf',
      });
      this.fileConversion = true;
    }
  }

  /**
   * Counting of scroll percentage
   * @param event - Event
   */
  private getScrollPercent(): number {
    // TODO: pageYOffset is deprecated
    return Math.round(
      ((this.windowRef.innerHeight + this.windowRef.scrollY) * 100) /
        this.documentRef.documentElement.scrollHeight
    );
  }

  /**
   * Checks if $percentageCounter reach required value
   * @param countStart start counter
   */
  private counterCheck(countStart: number): boolean {
    return (
      typeof this.percentageCounter === 'undefined' ||
      this.percentageCounter === 0 ||
      this.percentageCounter === countStart
    );
  }

  /**
   * Track scroll event
   * @param scrollArray - Array with scroll #
   */
  private analyticScroll(scrollArray: number[]): void {
    scrollArray.forEach((scroll) => {
      return this.analytics.trackEvent({
        event: 'scroll_depth',
        action: scroll.toString() + '%',
        detailed_event: 'Scroll Depth Tracking',
        event_data: {
          milestone: scroll.toString() + '%',
        },
      });
    });
  }

  /**
   * Returns alt text for image if clocked element is image
   * @param event - click event
   */
  private checkImageAlt(event: MouseEvent): string {
    if (event.target instanceof HTMLImageElement) {
      return event.target.alt;
    }
  }

  /**
   * Trigger Page View event using the AnalyticsService
   */
  private async triggerPageView(): Promise<void> {
    try {
      // Custom dimensions
      const customDimensions: CustomDimensions = await this.createCustomDimensions();

      // Event params
      const eventParams = {
        title: this.page.getComponent()?.getModels()?.pageData?.title,
        url: this.getRouteUrl(),
      };
      // Pass info to service
      this.analytics.pageViewWithCustomDimensions(
        customDimensions,
        eventParams
      );
    } catch (err) {
      logger.error(err);
    }
  }

  /**
   * Create Custom Dimensions to be sent before any other event
   * information.
   */
  private async createCustomDimensions(): Promise<CustomDimensions> {
    try {
      // Read metadata from page model
      const metadataComponent = this.page.getComponent(
        'page-config',
        'pageMetadata'
      );

      // Constants not used.
      // const pageMetadata = metadataComponent?.getModels()?.pageMetadata;
      // const siteMetadata = metadataComponent?.getModels()?.siteMetadata;

      const profileData: IUserProfile = await this.profileService
        .getUserProfile$()
        .pipe(take(1))
        .toPromise();
      const persistentAnalyticsData = await this.pageContainerService.getPartialDimensionOnLoginStatus(
        profileData?.isLoggedIn,
        profileData?.profileInfo
      );

      const pageData: AnalyticsPageData = this.page.getComponent()?.getModels()
        ?.pageData;
      const analyticsMetadata: PageAnalyticsMetadata =
        pageData?.analyticsMetadata;
      const fundName = this.pageDocument?.model?.data?.pageMetadata
        ?.campaignPageTagging;

      return {
        page_location: this.getRouteUrl(),
        event: 'page_load_started',
        detailed_event: 'Page Load Started',
        page_title: pageData?.title,
        page_type: pageData?.pageType,
        language_code: pageData?.language,
        role_type_track: profileData.profileInfo.role,
        market_code: this.siteConfigService?.product?.site?.country,
        is_internal_user: String(
          this.profileService.checkIfUserIsInternal(
            profileData?.profileInfo?.userGroup
          )
        ),
        express_number: persistentAnalyticsData?.expressNo,
        user_sys_no: persistentAnalyticsData?.userSysNo,
        dealer_number: profileData?.profileInfo?.dealerNo,
        state_code: profileData?.profileInfo?.state,
        zip: this.profileService.get5DigitZip(profileData?.profileInfo?.zip),
        accounts_assigned: profileData?.profileInfo?.accountsAccess,
        firm_name: profileData?.profileInfo?.firm,
        ost_id: profileData?.profileInfo?.ostId,
        role_selected: profileData?.profileInfo?.role,
        logged_in_status: profileData?.isLoggedIn
          ? IsLoginUser.LOGGEDIN
          : IsLoginUser.LOGGEDOUT,
        riva_id: profileData?.isLoggedIn
          ? profileData?.profileInfo?.rivaPartyId
          : '',
        user_group: profileData?.profileInfo?.userGroup,
        country_code: this.siteConfigService?.product?.site?.country,
        data_service_actor: profileData?.profileInfo?.actor,
        utm_source: this.utmValue?.utm_source,
        utm_medium: this.utmValue?.utm_medium,
        utm_campaign: this.utmValue?.utm_campaign,
        utm_content: this.utmValue?.utm_content,
        subsegment: profileData?.profileInfo?.webExperience,
        web_experience: profileData?.profileInfo?.firm,
        global_id: profileData.profileInfo.globalId,
        asset_class: this.joinAnalyticsMetadata(analyticsMetadata?.assetClass),
        fund_brand: this.joinAnalyticsMetadata(analyticsMetadata?.fundBrand),
        investment_team: this.joinAnalyticsMetadata(
          analyticsMetadata?.investmentTeam
        ),
        investment_theme: this.joinAnalyticsMetadata(
          analyticsMetadata?.investmentTheme
        ),
        page_data: {
          asset_class: this.joinAnalyticsMetadata(
            analyticsMetadata?.assetClass
          ),
          fund_brand: this.joinAnalyticsMetadata(analyticsMetadata?.fundBrand),
          investment_theme: this.joinAnalyticsMetadata(
            analyticsMetadata?.investmentTheme
          ),
          language_code: pageData?.language,
          page_location: this.getRouteUrl(),
          page_title: pageData?.title,
          page_type: pageData?.pageType,
          article_title: undefined,
          author: undefined,
          country: undefined,
          fund_category: undefined,
          fund_class: undefined,
          fund_name: fundName,
          fund_number: undefined,
          fund_tab: undefined,
          fund_ticker: undefined,
          fund_type: undefined,
          language: undefined,
          name: undefined,
          page_category: undefined,
          page_subcategory: undefined,
          personalization_experience: undefined,
          personalization_target: undefined,
          personalization_type: undefined,
          publication_series: undefined,
          publish_date: undefined,
          segmentation: undefined,
          type: undefined,
          vehicle: undefined,
        },
      };
    } catch (err) {
      throw err;
    }
  }

  /**
   * Returns string from PageAnalyticsMetadata array item
   * @param data - PageAnalyticsMetadata Array item
   */
  private joinAnalyticsMetadata(data: []): string {
    if (data && data?.length > 0) {
      return data.join(',');
    }
  }

  /**
   * Returns route URL + gateway for Gateway page
   * NGC-14437 analytics requirement to differentiate gateway from homepage
   */
  private getRouteUrl(): string {
    const isGateway: IsGateway = this.pageContainerService.getGateway();
    if (isGateway.isGateway) {
      let routeUrl = this.windowRef.location.href;
      // Need to add '/' before gateway for multilingual pages like /en-ca
      if (routeUrl.slice(-1) !== '/') {
        routeUrl = routeUrl + '/';
      }
      return routeUrl + isGateway.gatewayURL;
    }
    return this.windowRef.location.href;
  }

  /**
   * Initialise Dialog config
   */
  private initDialogConfig(customContent?: string): void {
    const labels = Labels.getLabels(LabelCollection.common);
    const leavingSite: LeavingSite = this.siteConfigService.getGlobalConfig()
      ?.siteConfiguration?.siteParams?.leavingSite;
    if (customContent && labels) {
      // Converting kebab case from class to camel case for label names
      // Labels for custom content for leaving site modal naming convention:
      // <externalLinkClass suffix>Title, <externalLinkClass suffix>Content
      // Example: leavingSsoTitle, leavingSsoContent
      // Suffix for external-link class should be kebab case: leaving-sso
      const customContentCamelize = customContent.replace(
        /-./g,
        (x) => x.toUpperCase()[1]
      );
      this.dialogConfigCustom = this.setDialogConfig(
        labels[`${customContentCamelize}Title`],
        labels[`${customContentCamelize}Content`],
        labels
      );
      this.dialogConfigCustom.eventName = customContent;
    }
    if (
      leavingSite?.leavingSiteContent &&
      leavingSite?.leavingSiteTitle &&
      labels
    ) {
      this.dialogConfig = this.setDialogConfig(
        leavingSite?.leavingSiteTitle,
        leavingSite?.leavingSiteContent,
        labels
      );
      return;
    }
    // If Leaving Site is not configured in site config, then taking from common labels.
    if (labels) {
      this.dialogConfig = this.setDialogConfig(
        labels.leavingSiteTitle,
        labels.leavingSiteContent,
        labels
      );
    }
  }

  /**
   * Sets dialog config parameters
   * @param titleLabel - Modal title string
   * @param contentLabel - Modal content string
   * @param labels - labels for buttons
   */
  private setDialogConfig(
    titleLabel: string,
    contentLabel: string,
    labels: any
  ): DialogConfig {
    return {
      title: titleLabel,
      content: `<p>${contentLabel}</p>`,
      cancelBtnLabel: labels.cancel,
      closeBtnLabel: labels.close,
      okBtnLabel: labels.ok,
    };
  }

  /**
   * If OneTrust is loaded toggle settings
   */
  private showCookieSettings(): void {
    if (this.windowRef.OneTrust != null) {
      this.windowRef.OneTrust.ToggleInfoDisplay();
    }
  }

  private setMetaData(page: Page): void {
    const metadata = page.getComponent().getModels().pageData;
    if (metadata) {
      if (metadata.title) {
        this.title.setTitle(
          metadata.title +
            (metadata.appendedTitleText
              ? ' | ' + metadata.appendedTitleText
              : '')
        );
      }
      this.updateMetaTags([
        { name: 'description', content: metadata.description },
        { name: 'keywords', content: metadata.keywords },
        { name: 'language', content: metadata.language },
        {
          property: 'og:title',
          content: this.getOgTitle(page),
        },
        { property: 'og:site_name', content: metadata.siteName },
        {
          property: 'og:description',
          content: this.getOgDescription(page),
        },
        {
          property: 'og:image',
          content: this.widenService.getWidenImageVariantUrl(
            metadata.socialImage,
            'original',
            'jpeg'
          ),
        },
        { name: 'twitter:site', content: metadata.twitterSite },
        {
          name: 'twitter:card',
          content: metadata?.twitterCard || 'summary_large_image',
        },
      ]);
      const robotsContent: string[] = [];
      robotsContent.push(metadata.noIndex ? 'noindex' : 'index');
      robotsContent.push(metadata.noFollow ? 'nofollow' : 'follow');
      this.meta.addTag({ name: 'robots', content: robotsContent.join(', ') });

      this.setCanonical(metadata.canonicalUrl);
      this.setFavicon(metadata.favicon);

      // Set lang attribute only if it exists
      if (metadata?.language && metadata?.language.trim().length > 1) {
        this.documentRef.documentElement.lang = metadata.language;
      }

      // Set default page title incase the above fails
      if (this.title.getTitle() === '') {
        this.title.setTitle('Franklin Templeton');
      }
    }
  }

  private getOgTitle(page: Page): string {
    const metadata = page.getComponent().getModels().pageData;
    if (metadata.socialTitle) {
      return metadata.socialTitle;
    }
    if (metadata.title) {
      return metadata.title;
    }
    const document: any = page.getDocument();
    const title = document?.model?.data?.title;
    return title;
  }

  private getOgDescription(page: Page): string {
    const metadata = page.getComponent().getModels().pageData;
    if (metadata.socialDescription) {
      return metadata.socialDescription;
    }
    if (metadata.description) {
      return metadata.description;
    }
    const document: any = page.getDocument();
    const cardSummary =
      document?.model?.data?.cardSummary || document?.model?.data?.description;
    return cardSummary;
  }

  private updateMetaTags(tags: MetaDefinition[]): void {
    tags.forEach((tag) => {
      if (tag.content && tag.content !== '') {
        let selector: string;
        if (tag.property != null) {
          selector = `property='${tag.property}'`;
        } else {
          selector = `name='${tag.name}'`;
        }
        this.meta.updateTag(tag, selector);
      }
    });
  }

  private setCanonical(url: string): void {
    const value = url ? url : this.windowRef.location.href;
    let link: HTMLLinkElement =
      this.documentRef.querySelector(`link[rel='canonical']`) || null;
    if (!link) {
      link = this.documentRef.createElement('link');
      link.setAttribute('rel', 'canonical');
      this.documentRef.head.appendChild(link);
    }
    link.setAttribute('href', value);
  }

  private setFavicon(url: string): void {
    if (url) {
      Array.from(this.documentRef.getElementsByTagName('link')).forEach(
        (link) => {
          if (link.rel === 'icon') {
            link.setAttribute('rel', 'shortcut icon');
            link.setAttribute('type', 'image/x-icon');
            link.setAttribute('href', url);
          }
        }
      );
    }
  }

  /**
   * Returns external custom content parameter for external modal
   * @param classList - DOMTokenList with anchor classes
   */
  private setExternalModalCustom(classList: DOMTokenList): string {
    return classList.value
      .split(' ')
      .find((css) => {
        return css.includes('external-link--');
      })
      .replace('external-link--', '');
  }

  /**
   * method removes parameter from URL
   * @param url - url string
   */
  private removeParam(url: string, parameter: string): string {
    return removeParam(url, parameter);
  }

  /**
   * Scrolls the page to the top when back to top button is clicked
   */
  public scrollToTop(event: MouseEvent): void {
    this.scrollService.scrollToTop();
    this.backToTopLink.nativeElement.focus();
    // Track BackToTopButton Event
    const target = (event.target as HTMLElement).innerText;
    this.analytics.trackEvent({
      event: 'BackToTopButton',
      action: 'Clicked',
      link_text: target,
      label: this.getRouteUrl(),
    });
  }

  /**
   * Setting attested access for the page.
   */
  private anonymousAttestedAccess(): void {
    if (this.isRoleSelectorTrigger) {
      this.roleSelectorService.closeRoleSelectorModal();
      this.isRoleSelectorTrigger = false;
      this.navService.navigateByUrl('/');
      return;
    }
    this.roleSelectorService.openRoleSelectorModal();
    this.isRoleSelectorTrigger = true;
  }

  /**
   *  Set segment for allowed user
   * @param allowedSegment - SegmentId
   */
  private anonymousAccess(allowedSegment: SegmentId): void {
    logger.debug(allowedSegment);
    this.storageService.retrieveSegment().then((segment: SegmentId) => {
      if (!segment) {
        this.segmentService.setSegment(allowedSegment);
      }
    });
  }

  showRoleSelectorDialog() {
    this.roleSelectorService.openRoleSelectorModal();
  }

  /**
   * Checks whether the layout should be handled with an external lib
   * @returns isExternalLayout - boolean
   */
  private isLayoutExternal(): boolean {
    let isExternalLayout = false;
    const layoutName = this.pageDocument?.model?.data?.contentType;
    if (layoutName === 'ftalts:ExperiencePage') {
      isExternalLayout = true;
    }
    return isExternalLayout;
  }

  /**
   * Converts bloomreach layouts into scss usable classes.
   * @param layout The layout to be transformed
   * @returns Class name associated with particular layout.
   */
  getCSSClass(layout: string): string {
    switch (layout.trim()) {
      case '1':
        return 'one';
      case '1 | 1':
        return 'one-one';
      case '1 | 1 | 1':
        return 'one-one-one';
      case '1 | 2':
        return 'one-two';
      case '2 | 1':
        return 'two-one';
      default:
        return 'default-layout';
    }
  }
}
